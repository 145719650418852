<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.4235 31.5732C36.1158 28.75 34.4886 27.3125 34.4886 19.527C34.4886 12.397 30.8469 9.85933 27.8512 8.625C27.6441 8.53835 27.4575 8.40909 27.3037 8.24564C27.1498 8.08219 27.032 7.88819 26.958 7.67625C26.4309 5.888 24.9589 4.3125 23.0001 4.3125C21.0413 4.3125 19.5673 5.88992 19.046 7.67817C18.9726 7.89022 18.8551 8.08427 18.7011 8.24749C18.5471 8.41072 18.3603 8.53939 18.1528 8.625C15.1533 9.86125 11.5154 12.3912 11.5154 19.527C11.5116 27.3125 9.88242 28.75 7.57476 31.5732C6.61834 32.7424 7.45592 34.5 9.12726 34.5H36.8806C38.5423 34.5 39.3742 32.7367 38.4235 31.5732Z"
      :stroke="borderColor"
      stroke-width="2.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.75 34.5V35.9375C28.75 37.4625 28.1442 38.925 27.0659 40.0034C25.9875 41.0817 24.525 41.6875 23 41.6875C21.475 41.6875 20.0125 41.0817 18.9341 40.0034C17.8558 38.925 17.25 37.4625 17.25 35.9375V34.5"
      :stroke="borderColor"
      stroke-width="2.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 39,
    },
    height: {
      type: [Number, String],
      default: 39,
    },
    borderColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>